import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import { useController, useEnvironment } from '@wix/yoshi-flow-editor';

import { EFeedStatus } from 'FeedWidget/store/feed/types';
import { feedSelectors } from 'FeedWidget/store/feed/adapter';
import { IFeedItem } from 'FeedWidget/api/types';

import { IControllerActions } from '../../types';

import * as virtual from './virtual';
import { FeedItem, FeedItemSkeleton } from './FeedItem';
import { feedSelector } from './selectors';

interface IFeedProps {
  groupId: string;
  onGuard(): void;
}

export function Feed(props: IFeedProps) {
  const { groupId, onGuard } = props;
  const { isPreview } = useEnvironment();
  const { methods } = useController() as IControllerActions;

  const state = useSelector(feedSelector);

  const items = feedSelectors.selectAll(state);
  const hasMore = state.nextCursor;

  const computeItemKey = useCallback(
    (index: number, item: IFeedItem) => item.feedItemId!,
    [],
  );

  useEffect(() => {
    methods.feed.fetch(groupId);
  }, [groupId, isPreview]);

  if ([EFeedStatus.init, EFeedStatus.loading].includes(state.status)) {
    return (
      <>
        <FeedItemSkeleton />
        <FeedItemSkeleton />
        <FeedItemSkeleton />
      </>
    );
  }

  return (
    <Virtuoso
      useWindowScroll
      data={items}
      endReached={handleLoadMore}
      computeItemKey={computeItemKey}
      overscan={300}
      components={virtual}
      itemContent={(_, item: IFeedItem) => (
        <FeedItem onGuard={onGuard} item={item} contextId={state.groupId!} />
      )}
    />
  );

  function handleLoadMore() {
    if (hasMore) {
      methods.feed.fetchMore(10);
    }
  }
}
