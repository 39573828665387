import React from 'react';
import { useController, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import cls from 'classnames';

import { SIZE, Tag } from 'wix-ui-tpa/Tag';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

import { Box } from 'common/components/Box/Box';
import {
  AuthorInfo,
  AuthorInfoSkeleton,
} from 'Group/Widget/Discussion/AuthorInfo';
import { placeholder, skeleton } from 'common/components/Skeleton';
import { PinIcon } from 'Group/Widget/icons/PinIcon';
import { IFeedItem } from 'FeedWidget/api/types';
import { IControllerActions } from 'FeedWidget/types';

import { Reactions } from './Reactions';
import { FeedItemComments } from './Comments';

import { classes } from './FeedItem.st.css';
import { FeedItemContent } from './FeedItemContent';
import { FeedItemActions } from './FeedItemActions';
import settingsParams from 'FeedWidget/settingsParams';

interface IFeedItemProps {
  item: IFeedItem;
  contextId: string;

  onGuard(): void;
}

function _FeedItem(props: IFeedItemProps) {
  const { item, contextId } = props;
  const { t } = useTranslation();
  const { methods } = useController() as IControllerActions;
  const settings = useSettings();

  const [topic] = item.entity?.topics;

  return (
    <Box withSideBorders className={classes.root}>
      {!!item.pin && (
        <div className={classes.pinnedHeader}>
          <PinIcon className={classes.icon} width={18} height={17} />
          <Text className={classes.headerText}>
            {t('groups-web.feed.pined-post')}
          </Text>
        </div>
      )}
      <div className={classes.meta}>
        <AuthorInfo
          activity={item.activity}
          name={item.createdBy.name || t('groups-web.member.anonymous')}
          avatarUrl={item.createdBy.imageUrl}
          timeStamp={item.createdAt}
          relationship={item.createdBy.role?.value}
          roles={[]}
        />
        <FeedItemActions item={item} />
      </div>
      <FeedItemContent item={item} />
      {topic?.displayName && (
        <Tag
          size={SIZE.small}
          className={classes.postTopic}
          onClick={() =>
            methods.group.redirectToGroup([['entity.topics', topic.id!]])
          }
        >
          {topic.displayName}
        </Tag>
      )}

      {settings.get(settingsParams.showReactions) && (
        <div className={classes.reactions}>
          <Reactions
            reactions={item.reactions}
            react={handleReact}
            unreact={handleUnreact}
          />

          <Text
            className={classes.totalComments}
            typography={TYPOGRAPHY.listText}
          >
            {t('groups-web.feed.comments.total', {
              count: item.comments?.total,
            })}
          </Text>
        </div>
      )}
      {settings.get(settingsParams.showComments) && (
        <FeedItemComments feedItemId={item.feedItemId} contextId={contextId} />
      )}
    </Box>
  );

  function handleReact(code: string) {
    if (!item.permissions?.canReact) {
      return props.onGuard();
    }

    return methods.feed.react(item.feedItemId!, code);
  }

  function handleUnreact(code: string) {
    if (!item.permissions?.canReact) {
      return props.onGuard();
    }

    return methods.feed.unreact(item.feedItemId!, code);
  }
}

_FeedItem.displayName = 'FeedItem';

export const FeedItem = React.memo(_FeedItem);

interface IFeedItemSkeletonProps {
  className?: string;
}

export function FeedItemSkeleton(props: IFeedItemSkeletonProps) {
  return (
    <Box className={cls(props.className)}>
      <div>
        <AuthorInfoSkeleton />
      </div>
      <br />
      <div className={cls(skeleton, placeholder)} />
      <br />
      <div>
        <Text typography={TYPOGRAPHY.smallTitle} className={skeleton}>
          Lorem.
        </Text>
        &nbsp;
        <Text typography={TYPOGRAPHY.smallTitle} className={skeleton}>
          Lorem.
        </Text>
        &nbsp;
        <Text typography={TYPOGRAPHY.smallTitle} className={skeleton}>
          Lorem.
        </Text>
      </div>
    </Box>
  );
}
