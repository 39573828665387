import React from 'react';

import { Components } from 'react-virtuoso';
import { CSSTransition } from 'react-transition-group';
import { classes } from 'FeedWidget/Widget/Feed/Feed.st.css';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

export const Item: Components['Item'] = (props) => {
  return (
    <CSSTransition
      {...props}
      mountOnEnter
      unmountOnExit
      classNames={classes as CSSTransitionClassNames}
      timeout={{ appear: 500, enter: 200 }}
    >
      <div className={classes.item}>{props.children}</div>
    </CSSTransition>
  );
};
