import React from 'react';
import { Components } from 'react-virtuoso';

import { FeedItemSkeleton } from '../FeedItem';
import { classes } from '../Feed.st.css';

export const ScrollSeekPlaceholder: Components['ScrollSeekPlaceholder'] = (
  props,
) => {
  return (
    <div className={classes.seekItemWrapper} style={{ height: props.height }}>
      <FeedItemSkeleton className={classes.seekItem} />
    </div>
  );
};
