import React, { useEffect, useMemo, useState } from 'react';
import { WidgetProps, ControllerProvider } from '@wix/yoshi-flow-editor';

import { Provider } from 'react-redux';

import {
  WixCommentsApiProvider,
  CommentsProps,
} from '@wix/comments-ooi-client';

import { AppDataContext } from 'Group/contexts/AppData/AppData';
import { HostContext } from 'Group/contexts/AppSettings/HostContext';
import { IControllerProps } from '../types';

import { createStore } from './store';
import { Widget } from './Widget';

function FeedWidget(props: WidgetProps<IControllerProps & CommentsProps>) {
  const store = useMemo(() => createStore(props.store), []);
  const { instance } = store.getState().application;

  const [controller] = useState({
    methods: {
      feed: props.feed,
      group: props.group,
    },
  });

  useEffect(() => {
    store.dispatch({
      type: 'CONTROLLER_UPDATE',
      payload: props.store,
    });
  }, [props.store]);

  return (
    <Provider store={store}>
      <AppDataContext.Provider value={{ instance } as any}>
        <HostContext.Provider value={props.host}>
          <ControllerProvider data={controller}>
            <WixCommentsApiProvider {...(props as any)}>
              <Widget />
            </WixCommentsApiProvider>
          </ControllerProvider>
        </HostContext.Provider>
      </AppDataContext.Provider>
    </Provider>
  );
}

export default FeedWidget;
