import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useController } from '@wix/yoshi-flow-editor';

import { DetailsComponent } from 'Group/Widget/Details/Details';
import { IControllerActions } from 'FeedWidget/types';
import settingsParams from 'FeedWidget/settingsParams';

import { IGroupState, IStore } from '../../store/types';

export function GroupDetails() {
  const { methods } = useController() as IControllerActions;
  const settings = useSettings();

  const group = useSelector<IStore, IGroupState>((state) => state.group);
  const groupId = settings.get(settingsParams.groupId);

  useEffect(() => {
    if (group.groupId !== groupId) {
      methods.group.fetchGroup(groupId);
    }
  }, [group.groupId, groupId]);

  if (!group.groupId || group.loading) {
    return null;
  }

  // @ts-expect-error
  return <DetailsComponent group={group} />;
}
