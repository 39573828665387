import React from 'react';

import { Spinner } from 'common/components/Spinner';

const Comments = React.lazy(() =>
  import(/* webpackChunkName: "comments" */ './Comments')
    .then((module) => ({ default: module.Comments }))
    .catch((e) => {
      return { default: () => null } as any;
    }),
);

export function FeedItemComments(props: any) {
  return (
    <React.Suspense fallback={<Spinner />}>
      <Comments {...props} />
    </React.Suspense>
  );
}
