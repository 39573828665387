import { Reducer } from 'redux';

import { IStore } from '../../store/types';

export function createStateReducer(state: keyof IStore, initial = {}) {
  return function (store = initial, action) {
    switch (action.type) {
      case 'CONTROLLER_UPDATE':
        if (JSON.stringify(store) !== JSON.stringify(action.payload[state])) {
          return action.payload[state];
        }
        return store;

      default:
        return store;
    }
  } as Reducer;
}
