import React from 'react';
import { useSelector } from 'react-redux';

import { FeedItemSkeleton } from 'FeedWidget/Widget/Feed/FeedItem';
import { feedSelector } from 'FeedWidget/Widget/Feed/selectors';

export function Footer() {
  const { nextCursor } = useSelector(feedSelector);

  return nextCursor ? (
    <>
      <FeedItemSkeleton />
      <FeedItemSkeleton />
    </>
  ) : null;
}
